import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {toAbsoluteUrl} from "../../_metronic/helpers";

const Result = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language
  const characterId = userInfo.character_id ?? null
  const [isPress, setIsPress] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const reportVideo = useRef()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ backgroundColor: '#000', height: '100%'}}>
      <div
        style={{
          display: !isPress ? 'block' : 'none',
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#000',
        }}
      >
        <div>
          <img src={toAbsoluteUrl('/media/logos/scanface.png')} style={{ width: WIDTH }} alt='intro' />
        </div>
        <div
          style={{
            width: '100%',
            padding: '30px',
            marginTop: '50px',
            marginBottom: '50px',
          }}
        >
          <button
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: '#30a3fc',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              borderWidth: '0px',
              display: 'block'
            }}
            onClick={() => {
              if (reportVideo.current) {
                reportVideo.current.play()
              }
              setIsPress(true)
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              NGHE PHÂN TÍCH
            </span>
          </button>

          <button
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: '#50f820',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              borderWidth: '0px',
              display: 'block',
              marginTop: '20px'
            }}
            onClick={() => {
              window.open('https://user.robotanan.com/auth/login?phone=' + userInfo.phone, '_self').focus()
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              ĐĂNG NHẬP
            </span>
          </button>
        </div>
      </div>


      <div
        style={{
          display: isPress ? 'block' : 'none',
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#000',
        }}
      >
        {characterId && <video ref={reportVideo} onEnded={() => setShowButton(true)} className='w-100' src={"https://backend.anbiz.vn/character/tinh_cach_" + characterId + ".mp4"} />}
        <div
          style={{
            display: showButton ? 'block' : 'none',
            width: '100%',
            padding: '30px',
            position: 'absolute',
            marginTop: '-400px'
          }}
        >
          <button
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: '#30a3fc',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              borderWidth: '0px',
              display: 'block'
            }}
            onClick={() => {
              setShowButton(false)
              reportVideo.current.play()
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              NGHE LẠI
            </span>
          </button>

          <button
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: '#68e25b',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              borderWidth: '0px',
              display: 'block',
              marginTop: '30px'
            }}
            onClick={() => {
              window.open('https://user.robotanan.com/auth/login?phone=' + userInfo.phone, '_self').focus()
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'XEM BÁO CÁO CHI TIẾT'
                :
                'CONTINUE'
              }
            </span>
          </button>
        </div>
      </div>





    </div>
  )
}

export {Result}
